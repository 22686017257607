<template>
    <modal ref="modalGestionarComision" titulo="Gestionar Comisión Pendiente" tamano="modal-lg" @guardar="updateEstadoComision">
        <div class="row mx-3">
            <img :src="datos.logo_lechero" width="46" height="46" class="rounded-circle border" />
            <div class="col-3">
                <p class="text-general f-20 f-600">
                    {{ datos.lechero }}
                </p>
                <p class="text-general f-14">
                    {{ datos.cedis_nombre }}
                </p>
            </div>
            <div class="col text-center text-general">
                <p class="f-20"> <b>{{ separadorNumero(datos.valor_condicion) }}</b> {{ sigla }}</p>
                <p>
                    <i class="icon-comision f-14 text-general" />
                    Comisión
                </p>
            </div>
            <div class="col text-center text-general">
                <p class="f-20"> <b>{{ separadorNumero(datos.total) }}</b> {{ sigla }}</p>
                <p>
                    <i class="icon-pedidos f-14 text-general" />
                    No. {{ datos.id }}
                </p>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-3    ">
            <p class="col-12 mb-3 text-general mt-4 f-17 f-500">
                Indica el estado de la comisión
            </p>
            <div class="col-auto d-middle-center">
                <input v-model="estado" :value="2" type="radio" class="option-input radio black" />
                <span class="text-general f-16">Ha sido cobrada</span>
            </div>
            <div class="col-auto d-middle-center">
                <input v-model="estado" :value="3" type="radio" class="option-input radio black" />
                <span class="text-general f-16">Será cobrada</span>
            </div>
            <div class="col-auto d-middle-center">
                <input v-model="estado" :value="1" type="radio" class="option-input radio black" />
                <span class="text-general f-16">No aplica comisión</span>
            </div>
        </div>
        <div class="row mx-0 py-3" />
    </modal>
</template>

<script>
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    data(){
        return {
            estado:0,
            datos:{}
        }
    },
    computed:{
        sigla(){
            let m = this.monedas.find(o=>o.id === this.datos.idm_moneda)
            if(m){
                return m.sigla
            }
            return ''
        }
    },
    methods: {
        toggle(row){
            this.datos = row
            this.estado = 0
            this.$refs.modalGestionarComision.toggle();
        },
        async updateEstadoComision(){
            try {
                if(this.estado === 0) return
                let form = {
                    id_pedido: this.datos.id,
                    estado:this.estado
                }
                const {data} = await Liquidacion.updateEstadoComision(form)
                this.notificacion('','Estado de la comisión actualizado','success')
                this.$emit('update',this.datos.id)
                this.$refs.modalGestionarComision.toggle();
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
